* {
  font-family: 'Nunito', sans-serif;
  --white: #ffffff;
  --primary-color: #7bc299!important;
  --secondary-color: #c9f2e1!important;
  --thirty-color: #eef8f4;
  box-sizing: border-box;
}

html,
body,
#root,
#root > div {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}